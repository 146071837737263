import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { Navigation, Grid } from 'swiper'; // Import Grid module
import axios from 'axios';
import { PLATFORM_PRIVATE_KEY, platformWalletAddress, API_URL } from '../../../api/constants'; // Import central wallet credentials
import Web3 from "web3";


const Author = (props) => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const data = props.data;
  console.log("data:", data);
  // Check if there is no data
  let web3;
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
  } else {
    console.error("Please install Metamask!");
  }

/*
  const handleAcceptBid = async (auctionId, projectId) => {
    try {
      // Call the backend API to update auction statuses and calculate shares
      console.log("auctionId, projectId:", auctionId, projectId);
      const response = await axios.post(`${API_URL}/bidders/acceptBid`, {
        auctionId,
        projectId,
      });
      console.log("response:", response.data);
      const { bidders, ownerWallet, ownerShare, partners } = response.data;

      // Handle refunds for all other bidders
      for (const bidder of bidders) {
        if (bidder.auction_id !== auctionId) {
          const tx = {
            from: platformWalletAddress,
            to: bidder.buyr_wallet_address,
            value: web3.utils.toWei(bidder.buyer_value, 'ether'), // Convert to wei
            gas: 21000, // Basic gas limit
          };

          const signedTx = await web3.eth.accounts.signTransaction(tx, PLATFORM_PRIVATE_KEY);
          await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
          console.log(`Refunded ${bidder.bid_value} ETH to ${bidder.wallet_address}`);
        }
      }

      // Handle share distribution
      const distributeShare = async (share, walletAddress) => {
        const tx = {
          from: platformWalletAddress,
          to: walletAddress,
          value: web3.utils.toWei(share.toString(), 'ether'),
          gas: 21000,
        };

        const signedTx = await web3.eth.accounts.signTransaction(tx, PLATFORM_PRIVATE_KEY);
        await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
        console.log(`Transferred ${share} ETH to ${walletAddress}`);
      };

      // Distribute shares to partners
      for (const partner of partners) {
        await distributeShare(partner.share, partner.wallet);
      }

      // Distribute the remaining share to the owner
      await distributeShare(ownerShare, ownerWallet);

      alert('Bid accepted, shares distributed, and refunds processed!');
    } catch (error) {
      console.error('Error accepting bid:', error);
      alert('Failed to accept bid. Please try again.');
    }
  };

*/
const handleAcceptBid = async (auctionId, projectId) => {
  try {
    // Call the backend API to update auction statuses and calculate shares
    console.log("auctionId, projectId:", auctionId, projectId);
    const userEmail = localStorage.getItem('email');
    const response = await axios.post(`${API_URL}/bidders/acceptBid`, {
      auctionId,
      projectId,
      userEmail,
    });
    console.log("response:", response.data);
    const { bidders, ownerWallet, ownerShare, partnerShares } = response.data;

    // Define common gas settings
    const gasLimit = 53000; // Adjust based on your transaction complexity
    const maxPriorityFeePerGas = web3.utils.toWei('2', 'gwei'); // For EIP-1559
    const maxFeePerGas = web3.utils.toWei('100', 'gwei'); // For EIP-1559

    // Refund all other bidders
    for (const bidder of bidders) {
      if (bidder.auction_id !== auctionId) {
        const tx = {
          from: platformWalletAddress,
          to: bidder.buyer_wallet_address, // Corrected property name
          value: web3.utils.toWei(bidder.buyer_value, 'ether'),
          gas: gasLimit,
          maxPriorityFeePerGas, // EIP-1559 parameter
          maxFeePerGas, // EIP-1559 parameter
        };

        const signedTx = await web3.eth.accounts.signTransaction(tx, PLATFORM_PRIVATE_KEY);
        await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
        console.log(`Refunded ${bidder.buyer_value} ETH to ${bidder.buyer_wallet_address}`);
      }
    }

    // Handle share distribution
    const distributeShare = async (share, walletAddress) => {
      const tx = {
        from: platformWalletAddress,
        to: walletAddress,
        value: web3.utils.toWei(share.toString(), 'ether'),
        gas: gasLimit,
        maxPriorityFeePerGas, // EIP-1559 parameter
        maxFeePerGas, // EIP-1559 parameter
      };

      const signedTx = await web3.eth.accounts.signTransaction(tx, PLATFORM_PRIVATE_KEY);
      await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
      console.log(`Transferred ${share} ETH to ${walletAddress}`);
    };
    setTimeout(() => {
      window.location.href = '/dashboard-owner'; // Redirect after successful login
  }, 3000);
    // Distribute shares to partners
    for (const partner of partnerShares) {
      await distributeShare(partner.share, partner.wallet);
    }

    // Distribute the remaining share to the owner
    await distributeShare(ownerShare, ownerWallet);

    alert('Bid accepted, shares distributed, and refunds processed!');
  } catch (error) {
    console.error('Error accepting bid:', error);
    alert('Failed to accept bid. Please try again.');
  }
};


  if (!data || data.length === 0) {
    return (
      <div className="tf-section flat-top-seller flat-auctions dark-style page">

        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3>So far, no bids has been received from bidders. As soon as a bid is received, it will be displayed on this page.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="tf-section flat-top-seller flat-auctions dark-style page">
        <div className="container">

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Swiper
                className="swiper-container carousel-7 show-shadow btn-collection2"
                spaceBetween={30}
                navigation
                modules={[Navigation, Grid]} // Include Grid module
                grid={{
                  rows: 2, // Number of rows
                  fill: 'row', // Fill slides row by row
                }}
                breakpoints={{
                  576: {
                    slidesPerView: 1, // On smaller screens, show 1 slide per view
                    grid: {
                      rows: 1, // Single row for smaller screens
                    },
                  },
                  992: {
                    slidesPerView: 2, // On screens 576px and larger, show 2 slides per view
                    grid: {
                      rows: 2, // Two rows for medium screens
                    },
                  },
                  1200: {
                    slidesPerView: 3, // On screens 992px and larger, show 3 slides per view
                    grid: {
                      rows: 2, // Two rows for larger screens
                    },
                  },
                }}
              >
                {data.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="wrap-seller center" style={{ width: 'calc(100% / 1.6)', margin: '1em' }}>
                      <div className="content">
                        <h3 style={{ marginBottom: '2em' }}>
                          By {item.title}
                        </h3>
                        <div className="price flex" style={{ marginBottom: '0.4em' }}>
                          <div className="title-price fs-16">Bidding Value:</div>
                        </div>
                        <div className="price flex" style={{ marginTop: '0' }}>
                          <img src={item.icon2} alt="" />
                          <div className="title-price fs-16">ETH {item.price}</div>
                        </div>
                        <div className="button-follow">
                          <button
                            className="sc-button btn-6"
                            onClick={() => handleAcceptBid(item.auctionId, item.projectId)}
                          >
                            {item.btn}
                          </button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

            </div>
          </div>
          <div className="back-arrow"
            style={{ fontSize: "1.5em" }}
            onClick={() => navigate(-1)}
          >
            <button>
              &#8592; Back
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Author;
