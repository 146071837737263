import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Profile from '../components/layouts/partnerdashboard/dashboard.jsx';
import dataProfile from '../assets/fake-data/data-partner-dashboard.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

import { fetchRequests , fetchProjects} from '../api/partnerDashboardApi.js'; // Import the fetchRequests function
import { fetchPartnerData } from '../api/partnerApi.js'; // Import the fetchRequests function

import imgauthor1 from '../assets/images/avatar/avt-01.png';

import icon1 from '../assets/images/icon/author-check.svg';
import icon2 from '../assets/images/icon/icon-diamond.svg';

import img1 from '../assets/images/box-item/auctions-1.jpg';
import img2 from '../assets/images/box-item/auctions-2.jpg';
import img3 from '../assets/images/box-item/auctions-3.jpg';
import img4 from '../assets/images/box-item/auctions-4.jpg';
import img5 from '../assets/images/box-item/auctions-5.jpg';

function PartnerDashboard(props) {
    const [profilePhoto, setProfilePhoto] = useState();
    //Fetch Project Request from Backend
    const [requestsData, setRequestsData] = useState([]); // State to store fetched Requests data
    const [projectData, setProjectData] = useState([]); // State to store fetched Requests data


    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        const fetchData = async () => {
            try {
                const partnerData = await fetchPartnerData(localStorage.getItem('email'));

                if (!partnerData) {
                    // Redirect to /login-partner if partnerData is not available
                    navigate('/register-partner');
                } else {
                    // Set the first name and last name to localStorage
                    localStorage.setItem('firstName', partnerData.first_name);
                    localStorage.setItem('lastName', partnerData.last_name);
                    setProfilePhoto(partnerData.owner_photo_link)
                }

                const requests = await fetchRequests();
                setRequestsData(requests);
                
                const projects = await fetchProjects(); // Fetch Requests data from backend API
                setProjectData(projects[0]); // Set fetched Requests data to state
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [navigate]);

    // Define the list of images
    const images = [img1, img2, img3, img4, img5];

    // Map the fetched Requests data to match the structure of dataLiveaution
    const mappedRequestsData = requestsData.map((request, index) => ({
        id: `${request.sub_id}`,
        projectId: `${request.project_id}`,
        img: images[index % images.length], // Use the modulo operator to cycle through the images
        tags: '6 New partners',
        imgauthor: imgauthor1,
        title: `${request.type}`, // Update with fetched Requests number
        projectName: `${request.project_name}`,
        author: `${request.owner_first_name} ${request.owner_last_name}`,
        partnerName: `${request.partner_first_name} ${request.partner_last_name}`,
        date: `${request.creation_date}`,
        titleprice: `EUR ${(request.estimatedCost / 1).toFixed(0)}`, // Update with fetched price
        btn: 'Check it!',
        btnAgreement: 'Sign the contract now',
        btnWaite: 'Partners are completing...',
        btnWaiteApprove: 'Wait for partners to confirm...',
        btnJudge: 'Judge It!',
        startDay: `${request.project_start_day}`,
        icon1: icon1,
        icon2: icon2,
        titlecountdown: 'Ending Time -',
        status: `${request.sub_status}`,
        category: `${request.category}`,
        percentage: `${request.percentage}`,
        partnerToken: `${request.partner_token}`,
        partnerCost: `${request.partner_cost}`,
        completion: `${(request.partner_completion)}`,
        partner_email: `${request.partner_email}`,
        top_partner_1_email: `${request.top_partner_1_email}`,
        top_partner_2_email: `${request.top_partner_2_email}`,
        top_partner_3_email: `${request.top_partner_3_email}`,
        top_partner_4_email: `${request.top_partner_4_email}`,
        top_partner_5_email: `${request.top_partner_5_email}`,
        top_partner_6_email: `${request.top_partner_6_email}`,
        top_partner_7_email: `${request.top_partner_7_email}`,
        top_partner_8_email: `${request.top_partner_8_email}`,
        top_partner_9_email: `${request.top_partner_9_email}`,
        top_partner_10_email: `${request.top_partner_10_email}`
    }));

    // Determine the number of rows to display based on the length of the fetched Requests data
    const numRowsToShow = mappedRequestsData.length > 3 ? 3 : mappedRequestsData.length;

    // Slice the mapped Requests data to display only the required number of rows
    const slicedRequestsData = mappedRequestsData.slice(0, numRowsToShow);


    // Combine the mapped Requests data with the remaining dataLiveaution
    const updatedDataLiveaution = [...mappedRequestsData, ...slicedRequestsData.slice(mappedRequestsData.length)];




    ////for projects
    const mappedProjectData = projectData.map((request, index) => ({
        id: `${request.project_id}`,
        projectId: `${request.project_id}`,
        img: images[index % images.length], // Use the modulo operator to cycle through the images
        tags: '6 New partners',
        imgauthor: imgauthor1,
        title: `${request.type}`, // Update with fetched Requests number
        projectName: `${request.project_name}`,
        author: `${request.owner_first_name} ${request.owner_last_name}`,
        partnerName: `${request.partner_first_name} ${request.partner_last_name}`,
        date: `${request.creation_date}`,
        titleprice: `EUR ${(request.estimatedCost / 1).toFixed(0)}`, // Update with fetched price
        btn: 'Check it!',
        btnAgreement: 'Sign the contract now',
        btnWaite: 'Partners are completing...',
        btnWaiteApprove: 'Wait for partners to confirm...',
        btnJudge: 'Judge It!',
        btnAuction: 'Check Auction!',
        startDay: `${request.project_start_day}`,
        icon1: icon1,
        icon2: icon2,
        titlecountdown: 'Ending Time -',
        status: `${request.project_status}`,
        category: `${request.category}`,
        percentage: `${request.percentage}`,
        partnerToken: `${request.partner_token}`,
        tokens:`${request.tokens}`,
        partnerCost: `${request.partner_cost}`,
        completion: `${(request.partner_completion)}`,
        partner_email: `${request.partner_email}`,
        top_partner_1_email: `${request.top_partner_1_email}`,
        top_partner_2_email: `${request.top_partner_2_email}`,
        top_partner_3_email: `${request.top_partner_3_email}`,
        top_partner_4_email: `${request.top_partner_4_email}`,
        top_partner_5_email: `${request.top_partner_5_email}`,
        top_partner_6_email: `${request.top_partner_6_email}`,
        top_partner_7_email: `${request.top_partner_7_email}`,
        top_partner_8_email: `${request.top_partner_8_email}`,
        top_partner_9_email: `${request.top_partner_9_email}`,
        top_partner_10_email: `${request.top_partner_10_email}`
    }));

    // Determine the number of rows to display based on the length of the fetched Requests data
    const numProjectsRowsToShow = mappedProjectData.length > 3 ? 3 : mappedProjectData.length;

    // Slice the mapped Requests data to display only the required number of rows
    const slicedProjectData = mappedProjectData.slice(0, numProjectsRowsToShow);


    // Combine the mapped Requests data with the remaining dataLiveaution
    const updatedProjectLiveaution = [...mappedProjectData, ...slicedProjectData.slice(mappedProjectData.length)];



        // Combine data into a single object
        const combinedData = {
            projects: updatedProjectLiveaution,
            subProjects: updatedDataLiveaution,
        };

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Dashboard' />
            {<Profile data={combinedData} avatar={profilePhoto} />}
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default PartnerDashboard;
