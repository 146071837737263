import axios from 'axios';

import { API_URL } from './constants';


export const fetchData = async (subProjectId) => {
    try {
        const response = await axios.get(`${API_URL}/partner-project-management/${subProjectId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendMessage = async (messageData) => {
    try {
        await axios.post(`${API_URL}/partner-project-management/send-message`, messageData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        throw error;
    }
};

export const finishProject = async (subProjectId) => {
    try {
      await axios.put(`${API_URL}/partner-project-management/${subProjectId}`, { status: 'not-sold' });
      // Optionally, you can handle success actions here, such as displaying a message or updating state
    } catch (error) {
      throw error;
    }
  };


  
export const juryProject = async (subProjectId, pmClaim, partnerJuryToken) => {
    try {
      const response = await axios.put(
        `${API_URL}/partner-project-management/jury/${subProjectId}`,
        { status: 'jury', pmClaim , partnerJuryToken}
      );
  
      console.log("API call succeeded:", response.data);
      return response.data; // Optionally return response data for further processing
    } catch (error) {
      console.error("API call failed:", error);
      throw error; // Re-throw to handle in the calling function
    }
  };