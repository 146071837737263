const menus = [

    {
        id: 1,
        name: 'Auction Market',
        links: '/live-auctions',
    },

    
]

export default menus;