import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";
import CardModal from "../../CardModal.jsx";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1/index.jsx';
import img1 from '../../.././assets/images/box-item/profile-photo.jpg';
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';
//import img2 from '../../.././assets/images/box-item/profile-cover.jpg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { fileBaseUrl } from '../../../api/constants.js';


const Profile1 = (props) => {
    useEffect(() => {
        // Check if the page has already been reloaded
        if (!sessionStorage.getItem('reloaded')) {
            // Reload the page
            sessionStorage.setItem('reloaded', 'true');
            window.location.reload();
        } else {
            // Clear the flag so that it doesn't persist
            sessionStorage.removeItem('reloaded');
        }
    }, []);
    const data = props.data.subProjects;
    const projectData = props.data.projects;

    const [modalShow, setModalShow] = useState(false);


    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleEditClick = () => {
        navigate('/editProfile-partner');
    };

    return (
        <Fragment>
            <div className="tf-section flat-author-profile flat-explore flat-auctions ">
                <div className="container">
                    <div className="flat-tabs tab-author">
                        <div className="author-profile flex" style={{ height: "15em", paddingTop: "0" }}>
                            <div className="feature-profile flex">
                                <div className="infor">
                                    <h3 className="fs-24 text-color-1">{localStorage.getItem('firstName')} {localStorage.getItem('lastName')}</h3>
                                    <div className="box-price flat-price">
                                        <div className="price flex">
                                            <div className="title-price text-color-1"> <br />Partner Dashboard</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="button-profile">
                                <div
                                    className="edit-arrow"
                                    style={{ cursor: 'pointer', fontSize: '1.7em', marginTop: '2em', textDecoration: 'underline' }}
                                    onClick={handleEditClick}
                                >
                                    Edit Profile
                                </div>
                            </div>
                        </div>
                        <Tabs>
                            <TabList className="menu-tab tab-title" style={{ backgroundColor: 'Black', paddingLeft: "8em", paddingRight: "4em", paddingTop: "4em", paddingBottom: "4em" }}>
                                <Tab><h3>Bidding opportunities</h3></Tab>
                                <Tab><h3>Projects in Progress</h3></Tab>
                                <Tab><h3>Decision making in Sales</h3></Tab>
                                <Tab><h3>Completed Projects & Auctions</h3></Tab>
                                <Tab><h3>Dealing with disputes</h3></Tab>

                            </TabList>
                            <TabPanel>
                                {
                                    data.filter(item => item.status === 'tendering').slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="content">
                                                <h3>
                                                    <Link to={{ pathname: "/project-detail", search: `?id=${item.id}` }}>
                                                        {item.title} for {item.projectName} project
                                                    </Link>
                                                </h3>
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> Owner: <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> share of the project: <span>{item.percentage}%</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">
                                                    <div className="price flex">
                                                        <div className="title-price">{item.titleprice}</div>
                                                    </div>
                                                    <div className="button-place-bid">
                                                        <Link to={{ pathname: "/project-detail", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" ><span>{item.btn}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </TabPanel>


                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3>Awaiting completion of partners and your approval</h3>
                                </Container>
                                {
                                    data.filter(item => item.partner_email === localStorage.getItem('email') && item.status === 'doing').slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="content">
                                                {item.completion === "true" && (
                                                    <h3><Link to={{ pathname: "/project-agreement", search: `?id=${item.projectId}&subId=${item.id}` }}>{item.title} for {item.projectName} project</Link></h3>
                                                )}
                                                {item.completion === "false" && (
                                                    <h3><Link>{item.title} for {item.projectName} project</Link></h3>
                                                )}

                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> Owner <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> Partner Tokens: <span>{item.partnerToken}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">

                                                    {item.completion === "true" && (
                                                        <div className="button-place-bid">
                                                            <Link to={{ pathname: "/project-agreement", search: `?id=${item.projectId}&subId=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"><span>{item.btnAgreement}</span></Link>
                                                        </div>)}
                                                    {item.completion === "false" && (
                                                        <div className="button-place-bid">
                                                            <Link data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" style={{ backgroundColor: "black" }}><span>{item.btnWaite}</span></Link>
                                                        </div>)}

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <Container>
                                    <hr style={{ margin: '20px 0', borderColor: '#ccc' }} />
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Pending approval from other partners<br /></h3>
                                </Container>

                                {
                                    data.filter(item => item.partner_email === localStorage.getItem('email') && item.status === 'doing2').slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="content">

                                                {item.completion === "true" && (
                                                    <h3><Link>{item.title} for {item.projectName} project</Link></h3>
                                                )}

                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> Owner <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> Partner Tokens: <span>{item.partnerToken}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">


                                                    {item.completion === "true" && (
                                                        <div className="button-place-bid">
                                                            <Link data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" style={{ backgroundColor: "black" }}><span>{item.btnWaiteApprove}</span></Link>
                                                        </div>)}

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <Container>
                                    <hr style={{ margin: '20px 0', borderColor: '#ccc' }} />
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Started and in progress <br /></h3>
                                </Container>

                                {
                                    data
                                        .filter(item => {
                                            // Prioritize items with status "doing3"
                                            if (item.status !== "doing3") return false;

                                            // Check if the user is the project manager
                                            if (item.pm_email === localStorage.getItem('email')) return true;

                                            // Check if the user is the partner
                                            return item.partner_email === localStorage.getItem('email');
                                        })
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h3>
                                                        <Link to={{ pathname: "/partner-project-management", search: `?id=${item.id}` }}>
                                                            {item.title} for {item.projectName} project
                                                        </Link>
                                                    </h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                {item.author && (
                                                                    <h5>
                                                                        Owner <h3 className="text-color-3" href="">{item.author}</h3>
                                                                    </h5>
                                                                )}
                                                                {item.startDay && (
                                                                    <h5>
                                                                        Project start day <h3 className="text-color-3" href="">{item.startDay}</h3>
                                                                    </h5>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="meta-price flex-one">

                                                        <div className="button-place-bid">
                                                            <Link to={{ pathname: "/partner-project-management", search: `?id=${item.id}` }}
                                                                data-toggle="modal"
                                                                data-target="#popup_bid"
                                                                className="sc-button btn-3">
                                                                <span>{item.btn}</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }


                            </TabPanel>
                            <TabPanel>
                                <Container>
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Choose the right price offer<br /></h3>
                                </Container>
                                {
                                    projectData.filter(item =>
                                        ['top_partner_1_email', 'top_partner_2_email', 'top_partner_3_email', 'top_partner_4_email', 'top_partner_5_email',
                                            'top_partner_6_email', 'top_partner_7_email', 'top_partner_8_email', 'top_partner_9_email', 'top_partner_10_email']
                                            .some(key => item[key] === localStorage.getItem('email'))
                                        && (item.status === 'not-sold')
                                    ).slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="content">
                                                <h3 style={{ marginBottom: "1em", marginTop: "0em" }}><Link >{item.projectName} project</Link></h3>
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> Owner: <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> Project Total Tokens: <span>{item.tokens}</span></div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">


                                                    <div className="button-place-bid">
                                                        <Link to={{ pathname: "/bidders", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" ><span>{item.btnAuction}</span></Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </TabPanel>
                            <TabPanel>
                                {
                                    data.filter(item => item.partner_email === localStorage.getItem('email') && (item.status === 'not-sold' || item.status === 'sold-out')).slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="content">
                                                <h6 className="tags">{item.status}</h6>

                                                <h3 style={{ marginBottom: "0em", marginTop: "2em" }}><Link >{item.title} for {item.projectName} project</Link></h3>
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> Owner <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> Partner Tokens: <span>{item.partnerToken}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">
                                                    <div className="price flex">
                                                        <div className="title-price">~ EUR {item.partnerCost}</div>
                                                    </div>
                                                    {/*
                                                    <div className="button-place-bid">
                                                        <Link to={{ pathname: "/partner-project-archive", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" ><span>{item.btn}</span></Link>
                                                    </div>
                                                    */}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </TabPanel>

                            <TabPanel>
                                <Container>
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Need Your Judge <br /></h3>
                                </Container>
                                {
                                    data.filter(item =>
                                        ['top_partner_1_email', 'top_partner_2_email', 'top_partner_3_email', 'top_partner_4_email', 'top_partner_5_email',
                                            'top_partner_6_email', 'top_partner_7_email', 'top_partner_8_email', 'top_partner_9_email', 'top_partner_10_email']
                                            .some(key => item[key] === localStorage.getItem('email'))
                                        && (item.status === 'jury')
                                    ).slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="content">
                                                <h3 style={{ marginBottom: "0em", marginTop: "0em" }}><Link >{item.title} for {item.projectName} project</Link></h3>
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> Owner: <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            {item.partnerName && (
                                                                <h5> Partner: <h3 className="text-color-3" href="">{item.partnerName}</h3> </h5>
                                                            )}
                                                            <div className="date"> Partner tokens: <span>{item.partnerToken}</span></div>
                                                            <div className="date"> Partner Share: <span>{item.percentage}%</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">


                                                    <div className="button-place-bid">
                                                        <Link to={{ pathname: "/jury-details", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" ><span>{item.btnJudge}</span></Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </TabPanel>




                        </Tabs>
                    </div>
                </div>
            </div>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment>
    );
};


export default Profile1;
