import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { fetchProjectData, updateProjectStatus, updateRejection } from "../../../api/agreementApi.js"; // Import API functions
//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";

// Register the components
ChartJS.register(ArcElement, Tooltip, Legend);

const AgreementProfile = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [projectData, setProjectData] = useState({
    initialManpower: [],
    initialMachines: [],
    initialMaterials: [],
  });
  const [manpower, setManpower] = useState([]);
  const [machines, setMachines] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [totalToken, setTotalToken] = useState([]);

  const projectId = new URLSearchParams(window.location.search).get('id');
  const subId = new URLSearchParams(window.location.search).get('subId');

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const data = await fetchProjectData(projectId);
        console.log("fetched data from back", data);
        const subprojects = data;
        console.log("subprojects from back:", subprojects.updatedSubprojects);
        console.log("subprojects from back:", subprojects.totalToken);
        setRequirements(data.updatedSubprojects);
        setTotalToken(data.totalToken);

        setProjectData({
          initialManpower: [],
          initialMachines: [],
          initialMaterials: [],
        });
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };
    getProjectData();
  }, [projectId]);

  // Sync other states with projectData
  useEffect(() => {
    setManpower(projectData.initialManpower);
    setMachines(projectData.initialMachines);
    setMaterials(projectData.initialMaterials);
  }, [projectData]);

  console.log('project Data:', projectData);
  console.log('manpower:', manpower);


  console.log("requirements:", requirements)

  //const partnerTotalTokens = 100000; // Total tokens for the project
  // Add `npv` to each item
  const discountRate = 0.05
  const dailyDiscountRate = discountRate / 365; //Daily Discount Rate for calculating NPV
  const requirementsWithNPV = requirements.map(item => {
    const npv = item.estimatedCost / Math.pow((1 + dailyDiscountRate), item.startDate);
    return { ...item, npv: Math.round(npv) };
  });
  console.log("requirementsWithNPV:", requirementsWithNPV);

  // Calculate the Owner Token
  const totalPartnerToken = requirements.reduce(
    (acc, item) => acc + Number(item.tokens), // Ensure type is a number
    0
  );
  const ownerTokens = totalToken - totalPartnerToken;

    // Calculate the Owner Percentage
    const totalPartnerPercentage = requirements.reduce(
      (acc, item) => acc + Number(item.percentage), // Ensure type is a number
      0
    );
    const ownerPercentage = 100 - totalPartnerPercentage;

    
  // Calculate the total estimatedCost and tokens
  const totalEstimatedCost = requirements.reduce(
    (acc, item) => acc + Number(item.estimatedCost), // Ensure type is a number
    0
  );
  console.log("Calculated total estimated cost:", totalEstimatedCost);

  // Calculate the total NPV and tokens
  const totalNpv = requirementsWithNPV.reduce(
    (acc, item) => acc + Number(item.npv), // Ensure type is a number
    0
  );
  console.log("Calculated total npv:", totalNpv);

  const partnerTotalTokens = totalNpv; // Total tokens for the project

  //add percentage to items
  const updatedRequirements = requirementsWithNPV.map(item => ({
    ...item,
    percentage: parseFloat(((Number(item.npv) / totalNpv) * 100).toFixed(2)),
  }));

  console.log("Updated requirements with percentages:", updatedRequirements);

  const requirementsWithTokens = updatedRequirements.map((item) => ({
    ...item,
    tokens: Math.round((item.percentage / 100) * partnerTotalTokens),
  }));
  console.log("final requirement:", requirementsWithTokens);
  // Prepare data for Pie Chart
  const pieData = {
    labels: requirementsWithTokens.map((item) => item.type),
    datasets: [
      {
        data: requirementsWithTokens.map((item) => item.percentage),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"], // Modern and attractive colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
      },
    ],
  };

  const tableHeaderStyle = { color: "white", fontWeight: "bold", fontSize: "1.2em", borderBottom: "2px solid white" };
  const tableBodyStyle = { color: "white", borderBottom: "1px solid white" };



  const [ownerToken, setOwnerToken] = useState(0); // Initialize ownerToken state
  const [totalTokens, setTotalTokens] = useState(0); // Initialize ownerToken state

  const handleInputChange = (event) => {
    const value = Number(event.target.value); // Convert input value to a number
    setOwnerToken(value); // Update ownerToken state
    setTotalTokens(value + partnerTotalTokens);

    console.log('Owner Token:', value); // Log for debugging
    console.log('Total Token ', value + partnerTotalTokens); // Log for debugging

  };

  const handleAccepet = async () => {
    try {
      // Update the project and subproject status to "doing2" 
      await updateProjectStatus(projectId, subId);
      toast.success('Your signature is saved, if other partners also accept, the project starts', { position: 'top-right', autoClose: 3000 });
      setTimeout(() => {
        navigate(`/dashboard-partner`);
      }, 4000);
    } catch (error) {
      console.error('Error posting subprojects:', error);
      alert('Failed to create subprojects. Please try again.');
    }
  };

  const handleReject = async () => {

    try {

      // Update the project and subproject status to "tendering" and delete partner
      const partnerEmail = localStorage.getItem("email");
      await updateRejection(projectId , subId);
      toast.success('This project has been removed for you.', { position: 'top-right', autoClose: 3000 });
      setTimeout(() => {
        navigate(`/dashboard-partner`);
      }, 4000);
    } catch (error) {
      console.error('Error posting subprojects:', error);
      alert('Failed to create subprojects. Please try again.');
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "15em" }}>
      <ToastContainer />

      <Typography variant="h4" gutterBottom>Project Requirements Final Valuation</Typography>

      {/* Requirements Table */}
      <TableContainer component={Paper} style={{ backgroundColor: "transparent", border: "1px solid white", marginBottom: "2em", marginTop: "2em" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle}>Number</TableCell>
              <TableCell style={tableHeaderStyle}>Requirement Title</TableCell>
              <TableCell style={tableHeaderStyle}>Prtner First Name</TableCell>
              <TableCell style={tableHeaderStyle}>Prtner Last Name</TableCell>
              <TableCell style={tableHeaderStyle}>Start (or Delivery) Day (From Start of the Project)</TableCell>
              <TableCell style={tableHeaderStyle}>Tokens</TableCell>
              <TableCell style={tableHeaderStyle}>Percentage of Project Value</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {requirements.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell style={tableBodyStyle}>{index + 1}</TableCell>
                <TableCell style={tableBodyStyle}>{item.type || '-'}</TableCell>
                <TableCell style={tableBodyStyle}>{item.partner_first_name || '-'}</TableCell>
                <TableCell style={tableBodyStyle}>{item.partner_last_name || '-'}</TableCell>
                <TableCell style={tableBodyStyle}>{item.startDate || '-'}</TableCell>
                <TableCell style={tableBodyStyle}>{item.tokens}</TableCell>
                <TableCell style={tableBodyStyle}>{`${item.percentage}%`}</TableCell>
              </TableRow>
            ))}
            {/* Total Row */}
            <TableRow>
              <TableCell style={tableHeaderStyle} colSpan={5}>Owner</TableCell>
              <TableCell style={tableHeaderStyle}>{ownerTokens}</TableCell>
              <TableCell style={tableHeaderStyle}>{ownerPercentage}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={tableHeaderStyle} colSpan={5}>Total Tokens</TableCell>
              <TableCell style={tableHeaderStyle}>{totalToken}</TableCell>
              <TableCell style={tableHeaderStyle}>100%</TableCell>
            </TableRow>
            
          </TableBody>
        </Table>
      </TableContainer>
      

      <Typography variant="h4" gutterBottom style={{ marginTop: "2em", marginBottom: "1em" }}>Project Start Date</Typography>
      <Typography variant="h5" gutterBottom>3 days after the approval of all partners is considered the official start day of the project and this date will be displayed on the project card in the next step.</Typography>


      <Typography variant="h4" gutterBottom style={{ marginTop: "4em", marginBottom: "1em" }}>Now declare your agreement or disagreement with the terms of the project:</Typography>

      <div
        style={{ marginBottom: "5em" }}
      >
        <button
          onClick={handleAccepet}
          style={{ backgroundColor: "darkgreen", width: "80%" }}
        >I agree to the terms of the contract and participate in this contract.
        </button>
      </div>
      <div>

        <button
          onClick={handleReject}
          style={{ backgroundColor: "darkred", width: "80%" }}

        >I disagree with the terms of the contract and am withdrawing from this contract.
        </button>
      </div>
    </Container>
  );
};

export default AgreementProfile;
