import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { Link } from "react-router-dom";
import CardModal from "../../CardModal.jsx";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1/index.jsx';
import img1 from '../../.././assets/images/box-item/profile.jpg';
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';
//import img2 from '../../.././assets/images/box-item/profile-cover.jpg';
import { Container, Dropdown } from "react-bootstrap";

import { fileBaseUrl } from '../../../api/constants.js';
//submit Request
//Bckend Connection
import { submitRequest } from "../../../api/requestApi.js";

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//components
import DownBut1 from "../../button/download1.js";

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// For plan
import { uploadPlan, fetchPlan, deletePlan } from '../../../api/ownerDashboardApi.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);


const Profile1 = (props) => {
    useEffect(() => {
        // Check if the page has already been reloaded
        if (!sessionStorage.getItem('reloaded')) {
            // Reload the page
            sessionStorage.setItem('reloaded', 'true');
            window.location.reload();
        } else {
            // Clear the flag so that it doesn't persist
            sessionStorage.removeItem('reloaded');
        }
    }, []);
    const projectData = props.data.projects;
    const subData = props.data.subProjects;
    const [modalShow, setModalShow] = useState(false);

    //////////////// General Settings


    //Phase list
    const [selectedPhase, setSelectedPhase] = useState("Select Current Phase of the Project");
    const [phaseList] = useState(
        [
            {
                id: 1,
                title: "Conceptual Design",
            },
            {
                id: 2,
                title: "Feasibility Study",
            },
            {
                id: 3,
                title: "Pre-Design",
            },
            {
                id: 4,
                title: "Schematic Design",
            },
            {
                id: 5,
                title: "Design Development",
            },
            {
                id: 6,
                title: "Construction Documentation",
            },
            {
                id: 7,
                title: "Permitting and Approvals",
            },
            {
                id: 8,
                title: "Bidding and Procurement",
            },
            {
                id: 9,
                title: "Pre-Construction",
            },
            {
                id: 10,
                title: "Under Construction",
            },
            {
                id: 11,
                title: "Inspection and Quality Control",
            },
            {
                id: 12,
                title: "Substantial Completion",
            },

        ]
    )
    const handlePhaseClick = (title) => {
        setSelectedPhase(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'initial_phase', value: title } });
    };


    //Projcet Type list
    const [selectedType, setSelectedType] = useState("Select Type of the Building");
    const [typeList] = useState(
        [
            {
                id: 1,
                title: "Residential-Apartment",
            },
            {
                id: 2,
                title: "Residential-Villa",
            },
            {
                id: 3,
                title: "Commercial",
            },
            {
                id: 4,
                title: "Educational",
            },
            {
                id: 5,
                title: "Healthcare",
            },
            {
                id: 6,
                title: "Industrial",
            },
            {
                id: 7,
                title: "Recreational",
            },
            {
                id: 8,
                title: "Institutional",
            },
            {
                id: 9,
                title: "Hospitality",
            },
            {
                id: 10,
                title: "Retail",
            },
            {
                id: 11,
                title: "Cultural",
            },
            {
                id: 12,
                title: "Transportation",
            },
            {
                id: 13,
                title: "Agricultural",
            },
            {
                id: 14,
                title: "Public Safety",
            },

        ]
    )
    const handleTypeClick = (title) => {
        setSelectedType(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'project_type', value: title } });
    };

    //Projcet Type list
    const [selectedCountry, setSelectedCountry] = useState("Select Country Name");
    const [countryList] = useState(
        [
            {
                id: 1,
                title: "Finland",
            },
            {
                id: 2,
                title: "Netherlands",
            },
            {
                id: 3,
                title: "Portugal",
            },
            {
                id: 4,
                title: "France",
            },
            {
                id: 5,
                title: "England",
            },
            {
                id: 6,
                title: "Norway",
            },
            {
                id: 7,
                title: "Spain",
            },
            {
                id: 8,
                title: "Denmark",
            },
        ]
    )
    const handleCountryClick = (title) => {
        setSelectedCountry(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'country', value: title } });
    };



    ///Submit Request:
    const stored_email = localStorage.getItem('email');
    const stored_first_name = localStorage.getItem('firstName');
    const stored_last_name = localStorage.getItem('lastName');
    const [plan, setPlan] = useState(img1);

    const [requestData, setRequestData] = useState({
        ownerEmail: stored_email,
        ownerFirstName: stored_first_name,
        ownerLastName: stored_last_name,
        project_name: '',
        project_type: '',
        initial_phase: '',
        land_area: 0,
        total_buildup_area: 0,
        floor_area: 0,
        number_of_floor: 0,
        units_per_floor: 0,
        total_units: 0,
        project_description: '',
        country: '',
        province: '',
        city: '',
        neighborhood: '',
        zip_code: '',
        plot_number: 0,
        address: '',
        map_link: '',
        accessibility: '',
        plan_link: '',
        threeD_link: '',
        attachments_link: ''
    });


    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    //for visibility of upload plan section
    const [uploadSectionVisible, setUploadSectionVisible] = useState(false);

    const [loading, setLoading] = useState(false); // State for loading indicator

    const handleCreate = async () => {
        setLoading(true); // Show loading indicator
        try {
            const response = await submitRequest(requestData);

            // Assuming the backend response includes the created project's ID
            const projectId = response.project_id;

            // Store the latest project ID in localStorage
            localStorage.setItem("latest_project_id", projectId);

            // Display the upload section
            setUploadSectionVisible(true);

            console.log("Project created successfully:", response);

            toast.success("Project created successfully. You can now upload a plan.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error("Error creating project:", error);
            toast.error("Failed to create project!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false); // Hide loading indicator
        }
    };


    const handleInputChange = (e) => {
        // Update the userData state when input fields change
        setRequestData({ ...requestData, [e.target.name]: e.target.value });
        //console.log('InputChange:',requestData )
    };


    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSettings = () => {
        setIsExpanded(!isExpanded);
    };

    const advButtonStyle = {
        padding: '10px 20px', // Adjust padding for desired size
        backgroundColor: 'rgb(241, 77, 128)', // Set background color using RGB values
        color: '#ffffff', // Set text color
        border: 'none', // Remove border
        borderRadius: '5px', // Add border radius for rounded corners
        cursor: 'pointer', // Change cursor to pointer on hover
    };
    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleEditClick = () => {
        navigate('/editProfile-owner');
    };


    ///plan upload
    const handlePlanUpload = async (event) => {
        event.preventDefault();
        const projectId = localStorage.getItem('latest_project_id');
        if (!projectId) {
            console.log("No email found in local storage.");
            return;
        }
        const formData = new FormData();
        //formData.append('plan_link', event.target.files);
        formData.append('plan', event.target.files[0]);
        console.log("sending file:", event.target.files[0]);
        formData.append('project_id', projectId);
        try {
            const response = await uploadPlan(projectId, formData);
            const planPath = `${fileBaseUrl}/${response.planPath}`; // Ensure the full URL is set
            setPlan(planPath);
            toast.success("Plan uploaded successfully!, Check Pre_Tender Projects");
        } catch (error) {
            console.error("Error uploading plan:", error);;
            toast.error("Error uploading plan.");
        }
    };

    const handlePlanDelete = async (event) => {
        event.preventDefault();
        const email = localStorage.getItem('email');
        if (!email) {
            console.log("No email found in local storage.");
            return;
        }

        try {
            await deletePlan(email);
            setPlan(img1);
            toast.success("Plan deleted successfully!");
        } catch (error) {
            console.error("Error deleting plan:", error);
            toast.error("Error deleting plan.");
        }
    };

    return (
        <Fragment>

            <div className="tf-section flat-author-profile flat-explore flat-auctions ">
                <div className="container">
                    <div className="flat-tabs tab-author">
                        <div className="author-profile flex" style={{ height: "15em", paddingTop: "0" }}>
                            <div className="feature-profile flex">
                                <div className="infor">
                                    <h3 className="fs-24 text-color-1">{localStorage.getItem('firstName')} {localStorage.getItem('lastName')}</h3>
                                    <div className="box-price flat-price">
                                        <div className="price flex">
                                            <div className="title-price text-color-1"> <br />Owner-Dashboard</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="button-profile">
                                <div
                                    className="edit-arrow"
                                    style={{ cursor: 'pointer', fontSize: '1.7em', marginTop: '2em', textDecoration: 'underline' }}
                                    onClick={handleEditClick}
                                >
                                    Edit Profile
                                </div>
                            </div>
                        </div>
                        <Tabs >
                            <TabList className="menu-tab " style={{ backgroundColor: 'Black', paddingLeft: "8em", paddingRight: "4em", paddingTop: "4em", paddingBottom: "4em" }}>
                                <Tab><h3>Create New Project</h3></Tab>
                                <Tab><h3>Pre-Tender Projects</h3></Tab>
                                <Tab><h3>Tendering Projects</h3></Tab>
                                <Tab><h3>Projects in Progress</h3></Tab>
                                <Tab><h3>Completed Projects & Auctions</h3></Tab>
                                <Tab><h3>Dealing with disputes</h3></Tab>
                            </TabList>
                            <TabPanel>
                                <ToastContainer /> {/* Add the ToastContainer component for showing messages */}

                                {
                                    <div className="tf-section flat-explore flat-edit-profile flat-auctions-details ">
                                        <div className="container">
                                            <div className="form-upload-profile post">
                                                <form action="#" className="form-profile" style={{ position: 'relative' }}>
                                                    <div className="form-infor-profile">
                                                        <h3 className="title-one fw-6">General Sepcs (Must be Filled)</h3>
                                                        <div className="form-infor flex flat-form"  >
                                                            <div className="info-account" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '1em' }}>
                                                                    <p className="title-infor-account">Project Name</p>
                                                                    <input type="text" placeholder="Enter Your Project Name" required name="project_name"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Land Area </p>
                                                                    <input type="number" placeholder="Enter Land Area (in m^2)" required name="land_area"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Number of Floors </p>
                                                                    <input type="number" placeholder="Enter Number of floors" required name="number_of_floor"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Project Description</p>
                                                                    <textarea tabIndex="4" rows="3" required name="project_description"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>



                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '0.9em', zIndex: 1 }}>
                                                                    <p className="title-infor-account">Building Type</p>
                                                                    <div id="item-create1" className="dropdown info-title" >
                                                                        <Dropdown
                                                                            style={{ marginBottom: '1em', zIndex: 1 }}>
                                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                                            >
                                                                                {selectedType}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                                {typeList.map(data => (
                                                                                    <Dropdown.Item key={data.id} onClick={() => handleTypeClick(data.title)}
                                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                                    >{data.title}</Dropdown.Item>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </fieldset>

                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Total Built-Up Area</p>
                                                                    <input type="number" placeholder="Enter Cumulative area of floors (in m^2)" name="total_buildup_area" style={{ maxHeight: "2.6em" }} required
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Number of Units per Floor</p>
                                                                    <input type="number" placeholder="Enter Number of Units per Floor" name="units_per_floor" style={{ maxHeight: "2.6em" }} required
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    />
                                                                </fieldset>

                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '2em', zIndex: 1 }}>
                                                                    <p className="title-infor-account">Current Phase of the Project</p>
                                                                    <div id="item-create1" className="dropdown info-title" >
                                                                        <Dropdown style={{ zIndex: 1 }}>
                                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                                            >
                                                                                {selectedPhase}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                                {phaseList.map(data => (
                                                                                    <Dropdown.Item key={data.id} onClick={() => handlePhaseClick(data.title)}
                                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                                    >{data.title}</Dropdown.Item>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "1em" }}>
                                                                    <p className="title-infor-account">Floor Area</p>
                                                                    <input type="number" placeholder="Enter Area per Floor (in m^2)" name="floor_area" style={{ maxHeight: "2.6em" }} required
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Total Number of Units</p>
                                                                    <input type="number" placeholder="Enter Total number of units" name="total_units" style={{ maxHeight: "2.6em" }} required
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    />
                                                                </fieldset>
                                                            </div>
                                                        </div>

                                                        <h3 className="title-one fw-6" style={{ marginTop: "2em" }}>Project Location Details</h3>
                                                        <div className="form-infor flex flat-form"  >
                                                            <div className="info-account" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '1em' }}>
                                                                    <p className="title-infor-account">Country</p>
                                                                    <div id="item-create1" className="dropdown info-title" >
                                                                        <Dropdown
                                                                            style={{ marginBottom: '1em', zIndex: 1 }}>
                                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                                            >
                                                                                {selectedCountry}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                                {countryList.map(data => (
                                                                                    <Dropdown.Item key={data.id} onClick={() => handleCountryClick(data.title)}
                                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                                    >{data.title}</Dropdown.Item>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Neighborhood/Locality </p>
                                                                    <input type="text" placeholder="Enter Neighborhood/Locality" required name="neighborhood"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Project Address</p>
                                                                    <textarea tabIndex="4" rows="3" required name="address"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>
                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 1 }}>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">State/Province/Region</p>
                                                                        <input type="text" placeholder="Enter State/Province/Region)" name="province" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">Postal/ZIP Code</p>
                                                                        <input type="text" placeholder="Enter Postal/ZIP Code" name="zip_code" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Map Link</p>
                                                                    <textarea tabIndex="4" rows="3" required name="map_link"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>

                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '0.9em', zIndex: 1 }}>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">City</p>
                                                                        <input type="text" placeholder="Enter City of Project" name="city" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">Plot Number</p>
                                                                        <input type="text" placeholder="Enter Plot Number" name="plot_number" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>

                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Site Accessibility Information </p>
                                                                    <textarea tabIndex="4" rows="3" required name="accessibility"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* Loading overlay */}
                                                    {loading && (
                                                        <div
                                                            style={{
                                                                position: "fixed",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "100%",
                                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                zIndex: 9999,
                                                            }}
                                                        >
                                                            <div style={{ color: "#fff", fontSize: "2em" }}>
                                                                Please wait while the project is built... <br /><br />
                                                                This may take a while....
                                                            </div>
                                                        </div>
                                                    )}

                                                    <button className="tf-button-submit mg-t-15" style={{ zIndex: 0 }}
                                                        onClick={e => {
                                                            e.preventDefault(); // Prevent the default form submission behavior
                                                            handleCreate();
                                                        }}>
                                                        Create Your Project
                                                    </button>
                                                </form>
                                                {uploadSectionVisible && (
                                                    <>
                                                        <h3 className="title-one fw-6" style={{ marginTop: "3em" }}>Upload Maps</h3>
                                                        <h4 className="title-one fw-6" style={{ marginTop: "1em" }}>Now upload your project plan as one pdf or dwg file</h4>

                                                        <div id="upload-profile" style={{ marginTop: "2em" }}>
                                                            <label className="btn-upload" style={{ padding: "1em", cursor: "pointer", backgroundColor: "darkyellow", maxWidth: "50%", justify: "center", position: "flex" }}>
                                                                Upload Project Plan
                                                                <input
                                                                    id="tf-upload-img"
                                                                    type="file"
                                                                    onChange={handlePlanUpload}
                                                                    style={{ display: "none" }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </TabPanel>
                            {/*2nd Tab - Pre-Tender */}
                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3>Check your created projects</h3>
                                    <p style={{ marginTop: "1em" }}>Check the requirements of your created projects and put them out to tender to find the best partners.</p>
                                </Container>
                                {
                                    projectData
                                        .filter(item => item.status === 'pretender') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h3><Link to={{ pathname: "/requirements", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                <div className="date"> Created On <span>{item.date}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                    >

                                                        <div className="button-place-bid"

                                                        >
                                                            <Link to={{ pathname: "/requirements", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 2em',  // Add padding for better spacing
                                                                    width: '21em',
                                                                    maxWidth: '21em',
                                                                    textAlign: 'center'
                                                                }}
                                                            ><span>{item.btn_pre}</span></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                }

                            </TabPanel>
                            {/*3rd Tab - Tendering */}
                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                </Container>
                                {
                                    subData
                                        .filter(item => item.status === 'tendering') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h6 className="tags">{item.title}</h6>
                                                    <h3 style={{ marginTop: "2em" }}><Link to={{ pathname: "/project-applicants", search: `?id=${item.id}` }}> {item.name}</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                <div className="date"> <span>{item.share}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>

                                                        <div className="button-place-bid">
                                                            <Link to={{ pathname: "/project-applicants", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 2em',  // Add padding for better spacing
                                                                    width: '21em',
                                                                    maxWidth: '21em',
                                                                    textAlign: 'center'
                                                                }}
                                                            ><span>{item.btn}</span></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                }
                            </TabPanel>

                            {/*4th Tab - in Progress */}
                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3 style={{ marginBottom: "2em" }}>Awaiting completion and approval from all partners</h3>
                                </Container>
                                {
                                    projectData
                                        .filter(item => item.status === 'doing') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h3 style={{ marginTop: "2em" }}><Link to={{ search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">

                                                            <div className="info">
                                                                <div className="date">
                                                                    Project Status: <br />
                                                                    <span>
                                                                        {item.completion === "false"
                                                                            ? "There are still requirements for which a partner has not been found."
                                                                            : "Partners are voting on the final terms of the project."}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                                <Container>
                                    <hr style={{ margin: '20px 0', borderColor: '#ccc' }} />
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Started and in progress <br /></h3>
                                </Container>

                                {
                                    subData
                                        .filter(item => item.status === 'doing3') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h6 className="tags">{item.title}</h6>
                                                    <h3 style={{ marginTop: "2em" }}>
                                                        <Link to={{ pathname: "/owner-project-management", search: `?id=${item.id}` }}>{item.name}</Link>
                                                    </h3>

                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                <div className="date"> <span>{item.partnerShare}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="button-place-bid">
                                                        <Link
                                                            to={{ pathname: "/owner-project-management", search: `?id=${item.id}` }}
                                                            data-toggle="modal"
                                                            data-target="#popup_bid"
                                                            className="sc-button btn-3"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                maxHeight: "3em",  // Adjust the height as needed
                                                                height: '3em',     // Ensure the button has enough height to center the text
                                                                padding: '0 2em',  // Add padding for better spacing
                                                                width: '21em',
                                                                maxWidth: '21em',
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <span>{item.btn_mgmt}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </TabPanel>


                            {/*5th Tab - Completed&Auctions */}

                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3>Projects Under Auction</h3>
                                </Container>
                                {
                                    projectData
                                        .filter(item => item.status === 'not-sold') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h6 className="tags">{item.title}</h6>
                                                    <h3 style={{ marginTop: "2em" }}><Link to={{ pathname: "/set-auction", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">

                                                            <div className="info">
                                                                <div className="date"> Total Tokens <span>  {item.totalToken}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>

                                                        <div className="button-place-bid">
                                                            <Link to={{ pathname: "/set-auction", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 2em',  // Add padding for better spacing
                                                                    width: '21em',
                                                                    maxWidth: '21em',
                                                                    textAlign: 'center'
                                                                }}
                                                            ><span>{item.btn_done1}</span></Link>
                                                        </div>
                                                    </div>
                                                    <div className="button-place-bid" style={{ marginTop: "2em" }}>
                                                        <Link
                                                            to={{ pathname: "/bidders", search: `?id=${item.id}` }}
                                                            data-toggle="modal"
                                                            data-target="#popup_bid"
                                                            className="sc-button btn-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                maxHeight: "3em",  // Adjust the height as needed
                                                                height: '3em',     // Ensure the button has enough height to center the text
                                                                padding: '0 1em',  // Add padding for better spacing
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <span>{item.reviewbtn_done1}</span>
                                                        </Link>
                                                    </div>


                                                </div>
                                            </div>

                                        ))
                                }
                                <Container>
                                    <hr style={{ margin: '20px 0', borderColor: '#ccc' }} />
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Sold Projects <br /></h3>
                                    {
                                        projectData
                                            .filter(item => item.status === 'sold-out') // Filter items by status
                                            .slice(0, 10000)
                                            .map((item, index) => (
                                                <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                    <div className="content">
                                                        <h3 style={{ marginTop: "2em" }}><Link>{item.title}</Link></h3>
                                                        <div className="meta-info mb-17">
                                                            <div className="author">

                                                                <div className="info">
                                                                    <div className="date"> Project Tokens <span>  {item.totalToken}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*
                                                        <div>
                                                            <div className="button-place-bid">
                                                                <Link to={{ pathname: "/set-auction", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        maxHeight: "3em",  // Adjust the height as needed
                                                                        height: '3em',     // Ensure the button has enough height to center the text
                                                                        padding: '0 2em',  // Add padding for better spacing
                                                                        width: '21em',
                                                                        maxWidth: '21em',
                                                                        textAlign: 'center'
                                                                    }}
                                                                ><span>{item.btn_done2}</span></Link>
                                                            </div>
                                                        </div>
                                                        <div className="button-place-bid" style={{ marginTop: "2em" }}>
                                                            <Link
                                                                to={{ pathname: "/dashboard-owner", search: `?id=${item.id}` }}
                                                                data-toggle="modal"
                                                                data-target="#popup_bid"
                                                                className="sc-button btn-2"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 1em',  // Add padding for better spacing
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <span>{item.reviewbtn_done2}</span>
                                                            </Link>
                                                        </div>
                                                        */}


                                                    </div>
                                                </div>

                                            ))
                                    }
                                </Container>
                            </TabPanel>


                            {/*6th Tab - Dispute Panel */}
                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3 style={{ marginBottom: "2em" }}>Need Your Judge</h3>
                                </Container>
                                {
                                    subData
                                        .filter(item => item.status === 'jury') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h3 style={{ marginBottom: "0em", marginTop: "0em" }}><Link >{item.name} for {item.title} project</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                {item.author && (
                                                                    <h5> Owner: <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                                )}
                                                                {item.partnerName && (
                                                                    <h5> Partner: <h3 className="text-color-3" href="">{item.partnerName}</h3> </h5>
                                                                )}
                                                                <div className="date"> Partner tokens: <span>{item.partnerToken}</span></div>
                                                                <div className="date"> Partner Share: <span>{item.percentage}%</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="meta-price flex-one">


                                                        <div className="button-place-bid">
                                                            <Link to={{ pathname: "/jury-details", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" ><span>{item.btnJudge}</span></Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                                
                            </TabPanel>

                        </Tabs>
                    </div >
                </div >
            </div >
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment >
    );
};


export default Profile1;
